
import { Component, Vue } from 'vue-property-decorator'
import { api } from '@/api'
import '@/assets/css/custom.css'
import '@/assets/css/style.min.css'
import Spinner from '@/components/Spinner.vue'

@Component({
  components: { Spinner },
})
export default class WalletConfirm extends Vue {

  public loading: boolean = false
  public message: string = ''

  async beforeMount() {
    const code = this.$route.query.code
    console.debug('%c CODE: %s', 'color:lime;', code)

    console.debug('%c this.$route.path: %s', 'color:gold;', this.$route.path)

    if (code) {
      this.message = this.$t('OperationError').toString()

      try {
        this.loading = true
        const data = {
          confirm_code: code.toString(),
        }

        if (this.$route.path === '/wallet-confirm') {
          // Подтверждение адреса кошелька
          const result = await api.walletConfirm(data)
          if (result.data) this.message = this.$t('WalletConfirmed').toString()
        } else {
          // this.$route.path === '/wallet-remove'
          // Удаление адреса кошелька
          const result = await api.walletRemove(data)
          if (result.data) this.message = this.$t('WalletRemoved').toString()
        }
      } catch (err) {
        console.error('%c WalletConfirm.vue onBeforeMount err.response:', 'color:red;', err.response)

        const errMsg = err.response?.data?.error
        if (errMsg === 'InvalidConfirmCode') {
          this.message = this.$t('InvalidConfirmCode').toString()
        }
      }
      this.loading = false
    } else {
      this.message = this.$t('NoConfirmationCode').toString()
    }
  }
}
